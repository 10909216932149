const releaseNotes = [
  {
    id: 47,
    title: "2.17.0",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Drobné úpravy</li>
      </ul>
    `,
    date: "12. 3. 2025",
  },
  {
    id: 46,
    title: "2.16.0",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Umožnění označit revizi za povinnou/nepovinnou</li>
      </ul>
    `,
    date: "12. 3. 2025",
  },
  {
    id: 46,
    title: "2.15.0",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Úprava středisek</li>
      </ul>
    `,
    date: "25. 2. 2025",
  },
  {
    id: 45,
    title: "2.14.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Zpřístupnění modulu Zařízení konkrétním uživatelům</li>
      </ul>
    `,
    date: "18. 12. 2024",
  },
  {
    id: 44,
    title: "2.13.0",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Možnost editace a odstraňování QR kódu</li>
        <li>Přidání stavů a vlastnictví pro zařízení</li>
      </ul>
    `,
    date: "17. 9. 2024",
  },
  {
    id: 43,
    title: "2.12.0",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Přidání zobrazení nejčastějších servisních firem</li>
      </ul>
    `,
    date: "14. 8. 2024",
  },
  {
    id: 42,
    title: "2.11.0",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Umožnění odstranění fotografií v modulu Zařízení</li>
      </ul>
    `,
    date: "14. 8. 2024",
  },
  {
    id: 41,
    title: "2.10.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Změna úložiště souborů</li>
      </ul>
    `,
    date: "14. 8. 2024",
  },
  {
    id: 40,
    title: "2.9.0",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Přidání filtrování servisních požadavků dle čísla</li>
      </ul>
    `,
    date: "31. 7. 2024",
  },
  {
    id: 39,
    title: "2.8.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Přidání modulu Zařízení</li>
      </ul>
    `,
    date: "30. 7. 2024",
  },
  {
    id: 38,
    title: "2.7.0",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Umožnění filtrování požadavků dle konkrétního technika</li>
      </ul>
    `,
    date: "6. 12. 2023",
  },
  {
    id: 37,
    title: "2.6.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Umožnění přidávání požadavků na více středisek zároveň (pro roli administrátora)</li>
        <li>Přidání odkazu do e-mailu pro rychlé otevření požadavku v aplikaci</li>
        <li>Přidání tlačítka pro zkopírování a sdílení adresy požadavku</li>
        <li>Přidání stránkování mezi požadavky do detailu požadavku</li>
      </ul>
    `,
    date: "14. 11. 2023",
  },
  {
    id: 36,
    title: "2.5.0",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Přidání nových konceptů</li>
        <li>Úpravy revizí a dokumentů</li>
        <li>Množství úprav UI</li>
      </ul>
    `,
    date: "13. 6. 2023",
  },
  {
    id: 35,
    title: "2.4.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Přidání modulu Dokumenty</li>
      </ul>
    `,
    date: "24. 5. 2023",
  },
  {
    id: 34,
    title: "2.3.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Úprava exportů vzhledem k filtrovaným servisním společnostem</li>
        <li>Přidání notifikace na vložené komentáře</li>
        <li>Aktivovány pravidelné reporty nevyřízených požadavků</li>
        <li>Přidán revizní modul v omezeném režimu</li>
      </ul>
    `,
    date: "3. 5. 2023",
  },
  {
    id: 33,
    title: "2.2.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Přidána kontrola servisních listů při uzavírání požadavku</li>
      </ul>
    `,
    date: "21. 2. 2023",
  },
  {
    id: 32,
    title: "2.1.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Přidána automatická aktualizace požadavků (1 min.)</li>
        <li>Aktivovány exporty</li>
        <li>Opravy drobných chyb</li>
      </ul>
    `,
    date: "11. 1. 2023",
  },
  {
    id: 31,
    title: "2.0.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Úprava uživatelského rozhraní – karta požadavku</li>
        <li>Přidání komentářů</li>
        <li>Přidání historie průběhu požadavku</li>
        <li>Umožnění nahrání více fotografií najednou</li>
        <li>Umožnění nahrání více servisních listů zároveň</li>
      </ul>
    `,
    date: "7. 1. 2023",
  },
  {
    id: 30,
    title: "1.28.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Přidání nového konceptu a poboček AFS</li>
      </ul>
    `,
    date: "29. 11. 2022",
  },
  {
    id: 29,
    title: "1.27.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Aktualizace uživatelského rozhraní</li>
        <li>Umožnění vložení servisních listů servisními společnostmi</li>
      </ul>
    `,
    date: "19. 7. 2022",
  },
  {
    id: 28,
    title: "1.26.0",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Nahrazení služby pro rozesílání e-mailových notifikací</li>
      </ul>
    `,
    date: "6. 10. 2021",
  },
  {
    id: 27,
    title: "1.25.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Přidání možnosti resetovat požadavek do výchozího stavu pro administrátora</li>
      </ul>
    `,
    date: "5. 10. 2021",
  },
  {
    id: 26,
    title: "1.24.0",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Přidání možnosti označit položku za vyřízenou regionálním manažerem</li>
      </ul>
    `,
    date: "15. 7. 2021",
  },
  {
    id: 25,
    title: "1.22.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Přidání širších informací o pobočce pro všechny role</li>
        <li>Informace je možné zobrazit po kliknutí na pobočku</li>
      </ul>
    `,
    date: "4. 5. 2021",
  },
  {
    id: 24,
    title: "1.21.0",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Rozšíření filtru o možnost skrytí vyřízených požadavků techniky</li>
      </ul>
    `,
    date: "21. 1. 2021",
  },
  {
    id: 23,
    title: "1.20.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Přidání uložení zvoleného filtru</li>
         <li>Filtr je uživateli uložen po kliknutí na tlačítko Uložit filtr</li>
         <li>Filtr je uložen i po odhlášení uživatele</li>
         <li>Filtr je uživateli přístupný i v případě přihlášení z jiného zařízení</li>
        <li>Přidání denního reportingu regionálním manažerům</li>
        <li>Množství drobných vylepšení</li>
      </ul>
    `,
    date: "21. 1. 2021",
  },
  {
    id: 22,
    title: "1.19.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Přidán týdenní reporting pobočkám o dlouhodobě nevyřízených požadavcích (každou neděli večer)</li>
        <li>Přidán týdenní reporting servisním společnostem o dlouhodobě neřešených požadavcích (každou neděli večer)</li>
      </ul>
    `,
    date: "13. 1. 2021",
  },
  {
    id: 21,
    title: "1.18.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Přidání notifikace pro pobočku o požadavcích starších 14 dnů</li>
        <li>Umožnění považování požadavku centrálou za vyřízený</li>
      </ul>
    `,
    date: "26. 11. 2020",
  },
  {
    id: 20,
    title: "1.17.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Umožnění instalace aplikace na Android zařízení</li>
      </ul>
    `,
    date: "3. 11. 2020",
  },
  {
    id: 19,
    title: "1.16.0",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Úprava mobilního zobrazení</li>
      </ul>
    `,
    date: "3. 11. 2020",
  },
  {
    id: 18,
    title: "1.15.0",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Přidání možnosti vložení a editace obrázku k požadavku pro administrátora a regionálního manažera</li>
      </ul>
    `,
    date: "15. 10. 2020",
  },
  {
    id: 17,
    title: "1.14.1",
    type: "Opravy",
    description: `
      <ul>
        <li>Opravy drobných chyb</li>
      </ul>
    `,
    date: "9. 10. 2020",
  },
  {
    id: 16,
    title: "1.14.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Přidání konceptů Relay, 1 minute, Inmedio, Hubiz, PAPER DOT</li>
      </ul>
    `,
    date: "29. 9. 2020",
  },
  {
    id: 15,
    title: "1.13.0",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Optimalizace zobrazení požadavků na mobilním zařízení</li>
        <li>Přesunutí fotografie požadavku dopředu</li>
        <li>Nasazení konceptu Autogrill</li>
      </ul>
    `,
    date: "10. 9. 2020",
  },
  {
    id: 14,
    title: "1.12.0",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Zvýrazněno tlačítko akce s požadavkem ("tři tečky")</li>
      </ul>
    `,
    date: "27. 8. 2020",
  },
  {
    id: 13,
    title: "1.11.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Přidání filtru dle konceptů</li>
        <li>Přidání role regionálního manažera</li>
        <li>Přidání zobrazení průběhu požadavku</li>
        <li>Množství drobných změn v rozhraní a textaci</li>
      </ul>
    `,
    date: "25. 8. 2020",
  },
  {
    id: 12,
    title: "1.10.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Přidání odeslání e-mailu pobočce, pokud je editována poznámka pro pobočku</li>
        <li>Umožnění znovu odeslání e-mailu servisní společnosti, pokud je servisní společnost změněna</li>
        <li>Drobné změny v rozhraní</li>
      </ul>
    `,
    date: "31. 7. 2020",
  },
  {
    id: 11,
    title: "1.9.0",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Drobné změny v UI</li>
        <li>Optimalizace automatických aktualizací</li>
      </ul>
    `,
    date: "28. 6. 2020",
  },
  {
    id: 10,
    title: "1.8.0",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Přidání drobných úprav v uživatelském rozhraní</li>
      </ul>
    `,
    date: "12. 6. 2020",
  },
  {
    id: 9,
    title: "1.7.0",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Zobrazení loga konceptu pobočky jako obrázek avatara</li>
      </ul>
    `,
    date: "9. 6. 2020",
  },
  {
    id: 8,
    title: "1.6.9",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Přidání odkazu na fotografii ve vysokém rozlišení do notifikačního e-mailu</li>
      </ul>
    `,
    date: "9. 6. 2020",
  },
  {
    id: 7,
    title: "1.6.8",
    type: "Novinky",
    description: `
      <ul>
        <li>Přidání přehledu změn</li>
      </ul>
    `,
    date: "4. 6. 2020",
  },
  {
    id: 6,
    title: "1.6.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Přidání možnosti přihlášení přes QR kód</li>
      </ul>
    `,
    date: "19. 5. 2020",
  },
  {
    id: 5,
    title: "1.5.0",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Přepracování e-mailových notifikací</li>
      </ul>
    `,
    date: "19. 5. 2020",
  },
  {
    id: 4,
    title: "1.4.4",
    type: "Vylepšení",
    description: `
      <ul>
        <li>Zobrazení autora vyřízení požadavku</li>
        <li>Zobrazení hodin ve 24hod. formátu</li>
        <li>Přidání drobných UI úprav</li>
      </ul>
    `,
    date: "18. 5. 2020",
  },
  {
    id: 3,
    title: "1.4.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Přidání detekce prohlížeče</li>
      </ul>
    `,
    date: "7. 2. 2020",
  },
  {
    id: 2,
    title: "1.3.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Přidání detekce prohlížeče</li>
      </ul>
    `,
    date: "7. 2. 2020",
  },
  {
    id: 1,
    title: "1.2.0",
    type: "Novinky",
    description: `
      <ul>
        <li>Přidání odkazu na uživatelský manuál</li>
        <li>Umožnění editace poznámek technikovi</li>
        <li>Zobrazení servisním společnostem, zda- li je pobočka v bezcelní zóně</li>
        <li>Zobrazení validačních hlášek ve stylu MUI</li>
        <li>Zobrazení validační hlášky v případě, že byly zadány přihlašovací údaje špatně(zobrazovalo blank page)</li>
        <li>Ošetření názvů uploadovanách souborů</li>
        <li>Logování user - agent uživatelů</li>
        <li>Zabezpečení login a files přes CORS</li>
      </ul>
    `,
    date: "4. 2. 2020",
  }
];
export default releaseNotes;
