import {
  createEntityAdapter,
  createSlice,
  EntityId,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  ListConfigType,
  Nullable,
  RevisionFilterType,
  RevisionType,
} from "../../../types";
import { buildInitialFilter } from "../builders/filter.builder";

type StateType = {
  filter: RevisionFilterType;
  list: EntityState<RevisionType>;
  listFetching: boolean;
  listFetchingPolling: {
    enabled: boolean;
    interval: number;
    nextFetchIn: number;
    lastChangeAt: string;
  };
  listConfig: ListConfigType;
  selectedRevision: Nullable<RevisionType>;
  selectedRevisionMode: Nullable<"create" | "update" | "read">;
  selectedUnitId: string | null;
};

export const revisionEntityAdapter = createEntityAdapter<RevisionType>();

const initialState: StateType = {
  filter: buildInitialFilter(),
  list: revisionEntityAdapter.getInitialState(),
  listFetching: false,
  listFetchingPolling: {
    enabled: true,
    interval: 5 * 60,
    nextFetchIn: 5 * 60,
    lastChangeAt: "1000-01-01",
  },
  listConfig: {
    page: 0,
    pageSize: 100,
    sortBy: null,
    sortByDirection: "ASC",
  },
  selectedRevision: null,
  selectedRevisionMode: null,
  selectedUnitId: null,
};

const revisionSlice = createSlice({
  name: "revision",
  initialState: initialState,
  reducers: {
    revisionListFetch: () => {},
    revisionListFetching: (
      state: StateType,
      action: PayloadAction<boolean>
    ) => {
      state.listFetching = action.payload;
    },
    listFetchingPolling: (state: StateType, action: PayloadAction<any>) => {
      state.listFetchingPolling = action.payload;
    },
    revisionListRemoveAll: (state: StateType) => {
      revisionEntityAdapter.removeAll(state.list);
    },
    revisionListSet: (
      state: StateType,
      action: PayloadAction<RevisionType[]>
    ) => {
      revisionEntityAdapter.setAll(state.list, action.payload);
    },
    revisionListUpsertMany: (
      state: StateType,
      action: PayloadAction<RevisionType[]>
    ) => {
      revisionEntityAdapter.upsertMany(state.list, action.payload);
    },
    revisionFilterSet: (
      state: StateType,
      action: PayloadAction<RevisionFilterType>
    ) => {
      state.filter = action.payload;
      state.listConfig = { ...state.listConfig, page: 0 };
    },
    revisionFilterPropertySet: (
      state: StateType,
      action: PayloadAction<{ property: string; value: any }>
    ) => {
      if (!state.filter) {
        state.filter = buildInitialFilter();
      }
      // @ts-ignore
      state.filter[action.payload.property] = action.payload.value;
      state.listConfig = { ...state.listConfig, page: 0 };
    },
    revisionListUpsertOne: (
      state: StateType,
      action: PayloadAction<RevisionType>
    ) => {
      revisionEntityAdapter.upsertOne(state.list, action.payload);
    },
    revisionListRemoveOne: (
      state: StateType,
      action: PayloadAction<EntityId>
    ) => {
      revisionEntityAdapter.removeOne(state.list, action.payload);
    },
    revisionDelete: (
      state: StateType,
      action: PayloadAction<RevisionType>
    ) => {},
    selectedRevisionSet: (
      state: StateType,
      action: PayloadAction<Nullable<RevisionType>>
    ) => {
      state.selectedRevision = action.payload;
    },
    selectedRevisionModeSet: (
      state: StateType,
      action: PayloadAction<Nullable<"create" | "update" | "read">>
    ) => {
      state.selectedRevisionMode = action.payload;
    },
    selectedRevisionPropertySet: (
      state: StateType,
      action: PayloadAction<any>
    ) => {
      if (!!state.selectedRevision) {
        // @ts-ignore
        state.selectedRevision[action.payload.property] = action.payload.value;
      }
    },
    selectedRevisionPropertiesSet: (
      state: StateType,
      action: PayloadAction<any>
    ) => {
      if (!!state.selectedRevision) {
        for (let i = 0; i < action.payload.length; i++) {
          // @ts-ignore
          state.selectedRevision[action.payload[i].property] =
            action.payload[i].value;
        }
      }
    },
    selectedRevisionSetById: (state: StateType, action) => {
      const revision = state.list.entities[action.payload];
      if (!!revision) {
        state.selectedRevision = revision;
        state.selectedRevisionMode = "read";
      }
    },
    selectedRevisionSubmit: (state: StateType) => {},
    selectedUnitIdSet: (
      state: StateType,
      action: PayloadAction<string | null>
    ) => {
      state.selectedUnitId = action.payload;
    },
    listConfigSet: (state: StateType, action: PayloadAction<any>) => {
      state.listConfig = { ...state.listConfig, ...action.payload };
    },
  },
});

export default revisionSlice;
