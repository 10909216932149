import { Helmet } from "react-helmet";
import AssetFinder from "../features/assetFinder/components/AssetFinder/AssetFinder";
import LayoutHome from "../features/common/components/Layout/LayoutHome";

const AssetFinderView = () => {
  return (
    <>
      <Helmet>
        <html lang="cs" />
        <title>Skenovat QR kód — Fixnote</title>
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>

      <LayoutHome>
        <AssetFinder anonymous={true} />
      </LayoutHome>
    </>
  );
};

export default AssetFinderView;
