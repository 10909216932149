import React, { useEffect, useState } from "react";
import {
  ButtonGroup,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Switch,
  useMediaQuery,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import {
  RevisionSubjectType,
  RevisionTemplateType,
  RevisionType,
  UnitType,
} from "../../../../types";
import ChipRevision from "../Chip/ChipRevision";
import { formatDate } from "../../../common/utils/dateTime.utils";
import { selectListFetching } from "../../../revision/selectors/revisionSelectors";
import { selectRevisionTemplates } from "../../selectors/revisionTemplate.selectors";
import RevisionActionUpgrade from "../../../revision/components/Action/RevisionActionUpgrade";
import RevisionActionDelete from "../../../revision/components/Action/RevisionActionDelete";
import { getRevisionExpirationStatusByRevision } from "../../../../libraries/enums/revisionExpirationStatuses";
import {
  REVISION_STATUS_CODE__invalidDueToExpiration,
  REVISION_STATUS_CODE__invalidDueToReplacement,
  REVISION_STATUS_CODE__valid,
} from "../../../../libraries/enums/revisionStatuses";
import TodayIcon from "@mui/icons-material/Today";
import { grey } from "@mui/material/colors";
import { selectRevisionCompanyById } from "../../../enum/selectors/enumSelectors";
import { USER_TYPE_CODE__revisionCompany } from "../../../../libraries/enums/userTypes";
import ChipUserType from "../../../issue/components/Chip/ChipUserType";
import { selectRevisionSubjectUnitRequirement } from "../../selectors/revisionSubjectUnitRequirement.selectors";
import revisionSubjectUnitRequirementSlice from "../../slices/revisionSubjectUnitRequirement.slice";
import { selectProfileHasPermissionRight } from "../../../common/selectors/commonSelectors";
import { PERMISSION_RIGHT_CODE__createRevisionSubjectUnitRequirement } from "../../../../libraries/enums/permissionRights";

const FieldRevisionRequirement = (props: {
  unitId: UnitType["id"];
  revisionSubject: RevisionSubjectType;
  revision?: RevisionType;
}) => {
  const { unitId, revisionSubject } = props;

  const dispatch = useAppDispatch();
  const hasPermissionRight = useAppSelector((state) =>
    selectProfileHasPermissionRight(state, {
      permissionRight:
        PERMISSION_RIGHT_CODE__createRevisionSubjectUnitRequirement,
    })
  );
  const revisionSubjectUnitRequirement = useAppSelector((state) =>
    selectRevisionSubjectUnitRequirement(state, {
      unitId,
      revisionSubjectId: revisionSubject.id,
    })
  );

  const [checked, setChecked] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    dispatch(
      revisionSubjectUnitRequirementSlice.actions.upsert({
        unitId,
        revisionSubjectId: revisionSubject.id,
        required: event.target.checked,
      })
    );
  };

  useEffect(() => {
    if (!!revisionSubjectUnitRequirement) {
      setChecked(revisionSubjectUnitRequirement.required);
    } else {
      setChecked(false);
    }
  }, [revisionSubjectUnitRequirement]);

  if (!hasPermissionRight) {
    return <></>;
  }

  return (
    <Switch
      size={"small"}
      checked={checked}
      onChange={handleChange}
      color={"secondary"}
      sx={{ mr: 0.25 }}
    />
  );
};

const FieldRevision = (props: {
  unitId?: UnitType["id"];
  revision?: RevisionType;
  revisionSubject: RevisionSubjectType;
}) => {
  const { unitId, revision, revisionSubject } = props;

  const revisionCompany = useAppSelector((state) =>
    !!revision
      ? selectRevisionCompanyById(state, revision.revisionCompanyId)
      : null
  );

  const expirationStatus =
    !!revision &&
    [
      REVISION_STATUS_CODE__valid,
      REVISION_STATUS_CODE__invalidDueToExpiration,
    ].includes(revision.statusId)
      ? getRevisionExpirationStatusByRevision(revision)
      : null;

  return (
    <>
      <Grid item md={5} sx={{ display: "flex", alignItems: "center" }}>
        {!!unitId && (
          <FieldRevisionRequirement
            unitId={unitId}
            revisionSubject={revisionSubject}
          />
        )}
        <ChipRevision
          revision={revision}
          revisionSubject={revisionSubject}
          size={"medium"}
        />{" "}
      </Grid>
      {!!revision && !!revisionCompany && (
        <>
          <Grid item md={2}>
            <ChipUserType
              userTypeCode={USER_TYPE_CODE__revisionCompany}
              label={revisionCompany.title}
            />
          </Grid>
          <Grid item md={2}>
            <Chip
              size={"small"}
              label={formatDate(
                revision.nextRevisionAt as string,
                "d. L. yyyy"
              )}
              icon={<TodayIcon />}
              sx={{
                mx: 1,
                color: grey[600],
                bgcolor: "rgba(0, 0, 0, 0.04)",
              }}
            />
          </Grid>
          {!!expirationStatus && (
            <Grid item md={2} {...expirationStatus.field.props}>
              {expirationStatus.field.getText(revision)}
            </Grid>
          )}
        </>
      )}
    </>
  );
};

const FieldRevisionActions = (props: {
  unitId?: UnitType["id"];
  revision?: RevisionType;
  revisionSubject: RevisionSubjectType;
}) => {
  const { revision } = props;

  if (!revision) {
    return <></>;
  }

  return (
    <Grid
      item
      container
      alignItems={"center"}
      justifyContent={"flex-end"}
      md={1}
    >
      <ButtonGroup variant={"outlined"}>
        <RevisionActionUpgrade revision={revision} />
        <RevisionActionDelete revision={revision} />
      </ButtonGroup>
    </Grid>
  );
};

const RevisionTemplateList = (props: {
  unitId: string;
  statusIds: string[];
}) => {
  const theme = useTheme();

  const fetching = useAppSelector(selectListFetching);
  const originalRows = useAppSelector((state) =>
    selectRevisionTemplates(state, { unitId: props.unitId })
  );

  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const columnsSizes = lgUp ? [10, 2] : [10, 2];

  const columns = lgUp
    ? [FieldRevision, FieldRevisionActions]
    : [FieldRevision, FieldRevisionActions];

  const rows = originalRows.filter((r) => {
    if (!!r.revision) {
      if (
        props.statusIds.includes(REVISION_STATUS_CODE__invalidDueToReplacement)
      ) {
        return true;
      } else {
        return (
          r.revision.statusId !== REVISION_STATUS_CODE__invalidDueToReplacement
        );
      }
    }
    return true;
  });

  return (
    <>
      <Box hidden={!fetching} sx={{ py: 5, textAlign: "center" }}>
        <CircularProgress />
      </Box>

      <Box
        hidden={fetching || rows.length !== 0}
        sx={{ color: "rgba(0, 0, 0, 0.24)", p: 2 }}
      >
        Není zde žádná revize.
      </Box>

      <Box hidden={fetching}>
        <Divider />
        {rows.map((revisionTemplate: RevisionTemplateType, index: number) => {
          const { revisionSubject, revision } = revisionTemplate;
          return (
            <>
              <Grid
                container
                alignItems="center"
                sx={{
                  transition: "background-color 200ms",
                  "&:hover": {
                    background: "rgb(225, 245, 254)",
                  },
                }}
                tabIndex={-1}
                key={[revisionSubject.id, !!revision ? revision.id : ""].join(
                  "_"
                )}
              >
                {columns.map((column, index) => {
                  return (
                    <>
                      {React.createElement(column, {
                        unitId: props.unitId,
                        revisionSubject: revisionSubject,
                        revision: revision,
                      })}
                    </>
                  );
                })}
              </Grid>
              <Divider />
            </>
          );
        })}
      </Box>
    </>
  );
};

export default RevisionTemplateList;
