import { loadAuthToken } from "../../../auth/utils/utils";
import GetAppIcon from "@mui/icons-material/GetApp";
import axios, { AxiosRequestConfig } from "axios";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { ButtonGroup } from "@mui/material";
import { useAppSelector } from "../../../../app/store";
import { selectRevisionIdsFilteredAndSortedWithoutListConfig } from "../../selectors/revisionSelectors";

const RevisionExportButton = () => {
  const revisionIds = useAppSelector(
    selectRevisionIdsFilteredAndSortedWithoutListConfig
  );

  const [loading, setLoading] = useState<boolean>(false);

  const handleExport = (type: "ALL" | "FILTERED" = "ALL") => {
    setLoading(true);
    const authToken = loadAuthToken();

    const data: { revisionIds: string[] } = {
      revisionIds: [],
    };

    if (type === "FILTERED") {
      data.revisionIds = revisionIds;
    }

    const config: AxiosRequestConfig = {
      url: process.env.REACT_APP_ENDPOINT_SERVER + "/export/revisions",
      responseType: "blob",
      headers: { Authorization: `Bearer ${authToken}` },
      method: type === "FILTERED" ? "POST" : "GET",
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const href = URL.createObjectURL(response.data);

        const dt = new Date();

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          "Fixnote - Report revizí z " + dt.toLocaleDateString() + ".xlsx"
        ); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ButtonGroup>
      <LoadingButton
        loading={loading}
        variant="outlined"
        startIcon={<GetAppIcon />}
        // sx={{
        //   mt: 2
        // }}
        // fullWidth
        onClick={() => {
          handleExport("FILTERED");
        }}
      >
        Exportovat
      </LoadingButton>
      {/*<LoadingButton*/}
      {/*  loading={loading}*/}
      {/*  variant="outlined"*/}
      {/*  startIcon={<GetAppIcon />}*/}
      {/*  // sx={{*/}
      {/*  //   mt: 2*/}
      {/*  // }}*/}
      {/*  // fullWidth*/}
      {/*  onClick={() => {*/}
      {/*    handleExport("ALL");*/}
      {/*  }}*/}
      {/*>*/}
      {/*  Exportovat vše*/}
      {/*</LoadingButton>*/}
    </ButtonGroup>
  );
};

export default RevisionExportButton;
