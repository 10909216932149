import { Button, ButtonGroup } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { selectSelectedIssuePrevNextIds } from "../../selectors/issueSelectors";
import issueSlice from "../../slices/issueSlice";

export const IssueDetailNavigation = () => {
  const dispatch = useAppDispatch();
  const ids = useAppSelector(selectSelectedIssuePrevNextIds);

  return (
    <ButtonGroup size="small" variant="outlined" color="secondary" sx={{ ml: 2 }}>
      <Button
        startIcon={<ChevronLeft />}
        disabled={!ids.prev}
        onClick={() => dispatch(issueSlice.actions.selectedIssueSetById(ids.prev))}
      >
        Novější
      </Button>
      <Button
        startIcon={<ChevronRight />}
        disabled={!ids.next}
        onClick={() => dispatch(issueSlice.actions.selectedIssueSetById(ids.next))}
      >
        Starší
      </Button>
    </ButtonGroup>
  );
}; 