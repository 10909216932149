import { useAppSelector } from "../../../../app/store";
import { selectAssetById } from "../../selectors/asset.selector";
import { Box } from "@mui/system";
import React from "react";
import { Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { formatDate } from "../../../common/utils/dateTime.utils";

const FieldDeliveryDate = ({
  assetId,
  isDetail = false,
}: {
  assetId: string;
  isDetail?: boolean;
}) => {
  const asset = useAppSelector((state) => selectAssetById(state, assetId));

  if (!asset) {
    return <></>;
  }

  if (!asset.deliveryDate) {
    return (
      <Box sx={{ p: 2, width: "100%", display: "flex" }}>
        <Typography
          variant={"body2"}
          sx={{
            fontStyle: "italic",
            color: grey[500]
          }}
        >
          není uvedeno
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2, width: "100%", display: "flex" }}>
      <Typography variant={"body2"}>
        {formatDate(asset.deliveryDate, "LLLL yyyy")}
      </Typography>
    </Box>
  );
};

export const fieldDeliveryDate = {
  code: "deliveryDate",
  label: "Datum dodání",
  component: FieldDeliveryDate,
};
