import { useState, useEffect } from "react";
import store, { useAppDispatch, useAppSelector } from "../../../app/store";
import {
  selectHasSelectedIssue,
  selectSelectedIssue,
  selectSelectedIssueClosingDialog,
  selectSelectedIssueMode,
} from "../selectors/issueSelectors";
import issueSlice from "../slices/issueSlice";

export const useIssueForm = () => {
  const dispatch = useAppDispatch();
  const hasIssue = useAppSelector(selectHasSelectedIssue);
  const isClosing = useAppSelector(selectSelectedIssueClosingDialog);
  const mode = useAppSelector(selectSelectedIssueMode);

  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const validate = (): string[] => {
    const errors: string[] = [];
    const issue = selectSelectedIssue(store.getState());

    if (issue) {
      if (!issue.unitId && !issue.unitIds) {
        errors.push("Středisko musí být vyplněno.");
      }
      if (!issue.description) {
        errors.push("Pole Požadavek musí být vyplněno.");
      }
    }

    return errors;
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setErrors([]);
      dispatch(issueSlice.actions.selectedIssueSet(null));
      dispatch(issueSlice.actions.selectedIssueModeSet(null));
    }, 250);
  };

  const handleSubmit = () => {
    const validationErrors = validate();
    if (validationErrors.length === 0) {
      dispatch(issueSlice.actions.selectedIssueSubmit());
      if (mode === "update") {
        dispatch(issueSlice.actions.selectedIssueModeSet("read"));
      } else {
        handleClose();
      }
    } else {
      setErrors(validationErrors);
    }
  };

  useEffect(() => {
    if (hasIssue) {
      setErrors([]);
      setOpen(true);
    }
  }, [hasIssue]);

  useEffect(() => {
    if (isClosing) {
      handleClose();
    }
  }, [isClosing]);

  return {
    open,
    errors,
    mode,
    handleClose,
    handleSubmit,
  };
};
