import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  useMediaQuery,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { useAppSelector } from "../../../../app/store";
import React from "react";
import {
  selectSelectedIssueProperty,
} from "../../selectors/issueSelectors";
import IssueDetailModeUpdate from "./IssueDetailModeUpdate";
import IssueDetailModeRead from "./IssueDetailModeRead";
import CloseButton from "../../../common/components/CloseButton/CloseButton";
import ChipIssueStatus from "../Chip/ChipIssueStatus";
import { ISSUE_STATUS_CODE__done } from "../../../../libraries/enums/issueStatuses";
import { useTheme } from "@mui/material/styles";
import { ContentCopy } from "@mui/icons-material";
import { IssueDetailNavigation } from "./IssueDetailNavigation";
import { useIssueForm } from "../../hooks/useIssueForm";

const IssueDetail = () => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const {
    open,
    errors,
    mode,
    handleClose,
    handleSubmit
  } = useIssueForm();

  const number = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "number" })
  );
  const issueOpen = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "open" })
  );
  const unitIds = useAppSelector((state) =>
    selectSelectedIssueProperty(state, { property: "unitIds" })
  );

  if (mode === "create" || mode === "update") {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        fullScreen={!mdUp}
      >
        <DialogTitle>
          {mode === "create" && !unitIds && "Přidat požadavek"}
          {mode === "create" && unitIds && "Přidat požadavek na více středisek"}
          {mode === "update" && "Zpracovat požadavek"}
          <CloseButton onClick={handleClose} />
        </DialogTitle>
        <Divider />
        <DialogContent>
          {mode === "create" && !unitIds && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              Každý požadavek prosím zadávejte jednotlivě.
            </Alert>
          )}
          {mode === "create" && unitIds && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              Můžete zvolit více středisek. Požadavek bude rozkopírován na
              všechna vybraná střediska.
            </Alert>
          )}
          <IssueDetailModeUpdate handleSubmit={handleSubmit} />
          {errors.length > 0 && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errors.join(" ")}
            </Alert>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            startIcon={<DoneIcon />}
            onClick={handleSubmit}
          >
            {mode === "create" ? "Vytvořit požadavek" : "Uložit požadavek"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      fullScreen={!mdUp}
    >
      <DialogTitle>
        #{number}{" "}
        {!issueOpen && (
          <ChipIssueStatus
            issueStatusCode={ISSUE_STATUS_CODE__done}
            label="Uzavřený"
          />
        )}
        <IssueDetailNavigation />
        <CloseButton onClick={handleClose} />
      </DialogTitle>
      <DialogContent dividers>
        <IssueDetailModeRead />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-start" }}>
        <Button
          variant="outlined"
          size="small"
          startIcon={<ContentCopy />}
          onClick={() => {
            window.navigator.clipboard.writeText(window.location.href);
          }}
        >
          Zkopírovat do schránky
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IssueDetail;
