import { RootState } from "../../../app/store";
import { createSelector } from "@reduxjs/toolkit";
import { revisionSubjectUnitRequirementEntityAdapter } from "../slices/revisionSubjectUnitRequirement.slice";

const selectors = revisionSubjectUnitRequirementEntityAdapter.getSelectors(
  (state: RootState) => state.revisionSubjectUnitRequirement.list
);

export const selectRevisionSubjectUnitRequirement = createSelector(
  [
    selectors.selectAll,
    (state, args) => args.unitId,
    (state, args) => args.revisionSubjectId,
  ],
  (items, unitId, revisionSubjectId) => {
    const index = items.findIndex((i) => {
      return i.unitId === unitId && i.revisionSubjectId === revisionSubjectId;
    });
    if (index === -1) {
      return null;
    }

    return items[index];
  }
);
