import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  IssueFilterType,
  IssueType,
  ListConfigType,
  Nullable,
} from "../../../types";
import { buildInitialFilter } from "../builders/filter.builder";

type StateType = {
  filter: IssueFilterType;
  list: EntityState<IssueType>;
  listFetching: boolean;
  listFetchingPolling: {
    enabled: boolean;
    interval: number;
    nextFetchIn: number;
    lastChangeAt: string;
  };
  listConfig: ListConfigType;
  selectedIssue: Nullable<IssueType>;
  selectedIssueMode: Nullable<"create" | "update" | "read">;
  selectedIssueClosingDialog: boolean;
  selectedUnitId: string | null;
  markingAsDoneConfirmDialog: {
    open: boolean;
    actionType: Nullable<string>;
  };
};

export const issueEntityAdapter = createEntityAdapter<IssueType>();

const initialState: StateType = {
  filter: buildInitialFilter(),
  list: issueEntityAdapter.getInitialState(),
  listFetching: false,
  listFetchingPolling: {
    enabled: true,
    interval: 5 * 60,
    nextFetchIn: 5 * 60,
    lastChangeAt: "1000-01-01",
  },
  listConfig: {
    page: 0,
    pageSize: 100,
    sortBy: null,
    sortByDirection: "ASC",
  },
  selectedIssue: null,
  selectedIssueMode: null,
  selectedIssueClosingDialog: false,
  selectedUnitId: null, //TODO - přesunout do jiného slice
  markingAsDoneConfirmDialog: {
    open: false,
    actionType: null,
  },
};

const issueSlice = createSlice({
  name: "issue",
  initialState: initialState,
  reducers: {
    issueListFetchByIssueIds: (
      state: StateType,
      actions: PayloadAction<{ issueIds: string[] }>
    ) => {},
    issueListFetch: () => {},
    issueListFetching: (state: StateType, action: PayloadAction<boolean>) => {
      state.listFetching = action.payload;
    },
    listFetchingPolling: (state: StateType, action: PayloadAction<any>) => {
      state.listFetchingPolling = action.payload;
    },
    issueListRemoveAll: (state: StateType) => {
      issueEntityAdapter.removeAll(state.list);
    },
    issueListSet: (state: StateType, action: PayloadAction<IssueType[]>) => {
      issueEntityAdapter.setAll(state.list, action.payload);
    },
    issueListUpsertMany: (
      state: StateType,
      action: PayloadAction<IssueType[]>
    ) => {
      issueEntityAdapter.upsertMany(state.list, action.payload);
    },
    issueFilterSet: (
      state: StateType,
      action: PayloadAction<IssueFilterType>
    ) => {
      state.filter = action.payload;
      state.listConfig = { ...state.listConfig, page: 0 };
    },
    issueFilterPropertySet: (
      state: StateType,
      action: PayloadAction<{ property: string; value: any }>
    ) => {
      if (!state.filter) {
        state.filter = buildInitialFilter();
      }
      // @ts-ignore
      state.filter[action.payload.property] = action.payload.value;
      state.listConfig = { ...state.listConfig, page: 0 };
    },
    issueListUpsertOne: (
      state: StateType,
      action: PayloadAction<IssueType>
    ) => {
      issueEntityAdapter.upsertOne(state.list, action.payload);
    },
    selectedIssueSet: (
      state: StateType,
      action: PayloadAction<Nullable<IssueType>>
    ) => {
      state.selectedIssue = action.payload;
    },
    selectedIssueModeSet: (
      state: StateType,
      action: PayloadAction<Nullable<"create" | "update" | "read">>
    ) => {
      state.selectedIssueMode = action.payload;
    },
    selectedIssuePropertySet: (
      state: StateType,
      action: PayloadAction<any>
    ) => {
      if (!!state.selectedIssue) {
        // @ts-ignore
        state.selectedIssue[action.payload.property] = action.payload.value;
      }
    },
    selectedIssuePropertiesSet: (
      state: StateType,
      action: PayloadAction<any>
    ) => {
      if (!!state.selectedIssue) {
        for (let i = 0; i < action.payload.length; i++) {
          // @ts-ignore
          state.selectedIssue[action.payload[i].property] =
            action.payload[i].value;
        }
      }
    },
    selectedIssueSetById: (state: StateType, action) => {
      const issue = state.list.entities[action.payload];
      if (!!issue) {
        state.selectedIssue = issue;
        state.selectedIssueMode = "read";
      }
    },
    selectedIssueSubmit: (state: StateType) => {},
    selectedIssueCommentSubmit: (state: StateType, action) => {},
    selectedUnitIdSet: (
      state: StateType,
      action: PayloadAction<string | null>
    ) => {
      state.selectedUnitId = action.payload;
    },
    listConfigSet: (state: StateType, action: PayloadAction<any>) => {
      state.listConfig = { ...state.listConfig, ...action.payload };
    },
    markingAsDoneConfirmDialogSet: (
      state: StateType,
      action: PayloadAction<any>
    ) => {
      state.markingAsDoneConfirmDialog = action.payload;
    },
    selectedIssueClosingDialogSet: (
      state: StateType,
      action: PayloadAction<boolean>
    ) => {
      state.selectedIssueClosingDialog = action.payload;
    },
  },
});

export default issueSlice;
