import React from "react";
import { Box, Chip, Grid, Typography } from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { RootState, useAppSelector } from "../../../../app/store";
import {
  selectAssetById,
  selectAssetRelations,
} from "../../selectors/asset.selector";
import { formatDate } from "../../../common/utils/dateTime.utils";
import ChipDate from "../../../common/components/Chip/ChipDate";
import { AssetUnitRelation } from "../../../../types/asset.types";
import { shallowEqual } from "react-redux";
import UnitThumbnailContent from "../../../unit/components/UnitThumbnail/UnitThumbnailContent";
import { getLastAssetMovement } from "../../utils/assetMovement.utils";

const fDate = (dtString: string | null) => {
  if (!dtString) {
    return "neuvedeno";
  }
  return formatDate(dtString, "d. L. Y HH:mm");
};

const Field = ({
  assetId,
  isDetail = false,
  context = "DETAIL_UNIT_RELATIONS",
}: {
  assetId: string;
  isDetail?: boolean;
  context?: "LIST" | "DETAIL_UNIT" | "DETAIL_UNIT_RELATIONS";
}) => {
  const asset = useAppSelector((state) => selectAssetById(state, assetId));
  const relations = useAppSelector(
    (state: RootState) =>
      selectAssetRelations(state, {
        assetId: assetId,
        relationName: "unitRelations",
      }),
    {
      equalityFn: shallowEqual,
    }
  );
  const lastAssetMovement = getLastAssetMovement(asset);

  const rels =
    relations.length > 0 && context === "DETAIL_UNIT"
      ? [relations[relations.length - 1]]
      : relations;

  if (isDetail && rels.length === 0) {
    return (
      <Box sx={{ p: 2, width: "100%", display: "flex" }}>
        <Typography
          variant={"body2"}
          sx={{
            fontStyle: "italic",
            color: grey[500]
          }}
        >
          není uvedeno
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {rels.map((relation: AssetUnitRelation) => {
        return (
          <Grid
            container
            key={relation.id}
            sx={{
              alignItems: "center",
              opacity:
                (lastAssetMovement?.direction?.id || "-1") === "OUT" ? 0.5 : 1,
            }}
          >
            <Grid item xs={isDetail ? 5 : 12}>
              <UnitThumbnailContent
                unit={{
                  id: relation.unit.id,
                  subtitle: relation.unit.subtitle || "",
                  title: relation.unit.title || "",
                }}
                brand={{
                  code: relation.unit.brand?.code || "",
                }}
                canShowDetail={false}
              />
            </Grid>
            {isDetail && (
              <>
                <Grid item xs={7}>
                  <Box>
                    {context === "DETAIL_UNIT_RELATIONS" && (
                      <ChipDate label={fDate(relation.date)} />
                    )}
                    <Typography sx={{ fontSize: "80%", mt: 0.5, pl: 1 }}>
                      {relation.placement}
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        );
      })}
    </>
  );
};

export const fieldUnitRelations = {
  code: "fieldUnitRelations",
  label: "Pohyby a umístění v čase",
  valueGetterFn: (obj: any) => {
    return "";
  },
  component: Field,
};
