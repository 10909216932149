import {
  AnyAction,
  createListenerMiddleware,
  ListenerMiddlewareInstance,
} from "@reduxjs/toolkit";
import { ListenerApiType } from "../../../types/api.types";
import revisionSubjectUnitRequirementSlice from "../slices/revisionSubjectUnitRequirement.slice";
import {
  revisionSubjectUnitRequirementGetAll,
  revisionSubjectUnitRequirementUpsertPost,
} from "../../../api/api";

const revisionSubjectUnitRequirementMiddleware: ListenerMiddlewareInstance =
  createListenerMiddleware();

//inicializační načtení
revisionSubjectUnitRequirementMiddleware.startListening({
  type: revisionSubjectUnitRequirementSlice.actions.listFetch.type,
  effect: async (action: AnyAction, listenerApi: ListenerApiType) => {
    listenerApi.dispatch(
      revisionSubjectUnitRequirementSlice.actions.listFetching(true)
    );
    revisionSubjectUnitRequirementGetAll({})
      .then((response: any) => {
        if (response.status === 200) {
          listenerApi.dispatch(
            revisionSubjectUnitRequirementSlice.actions.listSet(
              response.data.revisionSubjectUnitRequirements
            )
          );
        }
      })
      .finally(() => {
        listenerApi.dispatch(
          revisionSubjectUnitRequirementSlice.actions.listFetching(false)
        );
      });
  },
});

// při odeslání
revisionSubjectUnitRequirementMiddleware.startListening({
  type: revisionSubjectUnitRequirementSlice.actions.upsert.type,
  effect: async (action, listenerApi) => {
    // @ts-ignore
    revisionSubjectUnitRequirementUpsertPost({ data: action.payload }).then(
      (response: any) => {
        if (response.status === 200) {
          listenerApi.dispatch(
            revisionSubjectUnitRequirementSlice.actions.listUpsertMany([
              response.data.revisionSubjectUnitRequirement,
            ])
          );
        }
      }
    );
  },
});

export default revisionSubjectUnitRequirementMiddleware;
