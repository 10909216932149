import React from "react";
import {
  Divider,
  Grid,
  Hidden,
  Paper,
  TablePagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { Box, Container } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import {
  selectFlattenUnitIdsFilteredAndSorted,
  selectFlattenUnitsFilteredAndSortedCount,
  selectListConfig,
} from "../../selectors/unit.selector";
import unitSlice from "../../slices/unit.slice";
import UnitFilter from "../UnitFilter/UnitFilter";
import UnitFilterBrand from "../UnitFilter/UnitFilterBrand";
import { selectUnitById } from "../../../enum/selectors/enumSelectors";
import FieldUnitContent from "../../../enum/components/FieldUnitContent/FieldUnitContent";
import PageHeading from "../../../common/components/PageHeading/PageHeading";

const FieldUnitProperty = (props: any) => {
  const { unitId, property } = props;

  const unit = useAppSelector((state) => selectUnitById(state, unitId));

  if (!unit) {
    return <></>;
  }

  //@ts-ignore
  const value = unit[property] || "";

  return (
    <>
      <Box p={2}>
        <Typography variant={"body2"}>{value}</Typography>
      </Box>
    </>
  );
};

const fieldUnit = {
  code: "fieldUnit",
  label: "Středisko",
  component: FieldUnitContent,
  componentProps: {},
};

const fieldAddress = {
  code: "fieldAddress",
  label: "Adresa",
  component: FieldUnitProperty,
  componentProps: {
    property: "addressText",
  },
};

const fieldEmail = {
  code: "fieldEmail",
  label: "Email",
  component: FieldUnitProperty,
  componentProps: {
    property: "email",
  },
};

const fieldTelephone = {
  code: "fielTelephone",
  label: "Telefon",
  component: FieldUnitProperty,
  componentProps: {
    property: "telephone",
  },
};

const fieldOpeningHours = {
  code: "fieldOpeningHours",
  label: "Provozní doba",
  component: FieldUnitProperty,
  componentProps: {
    property: "openingHoursText",
  },
};

const fieldMandant = {
  code: "fieldMandant",
  label: "Provozovatel",
  component: FieldUnitProperty,
  componentProps: {
    property: "mandantText",
  },
};

const UnitList = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const rows = useAppSelector(selectFlattenUnitIdsFilteredAndSorted);
  const listConfig = useAppSelector(selectListConfig);
  const count = useAppSelector(selectFlattenUnitsFilteredAndSortedCount);

  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const columnsSizes = lgUp ? [2, 2, 2, 2, 2, 2] : [2, 2, 2, 2, 2, 2];

  const columns = lgUp
    ? [
      fieldUnit,
      fieldAddress,
      fieldEmail,
      fieldTelephone,
      fieldOpeningHours,
      fieldMandant,
    ]
    : [
      fieldUnit,
      fieldAddress,
      fieldEmail,
      fieldTelephone,
      fieldOpeningHours,
      fieldMandant,
    ];

  const handlePageChange = (event: any, page: any) => {
    dispatch(unitSlice.actions.listConfigSet({ page: page }));
  };

  const handleRowsPerPageChange = (event: any) => {
    dispatch(unitSlice.actions.listConfigSet({ pageSize: event.target.value }));
  };

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ mr: 2 }}>
              <PageHeading pageName="Střediska" />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Paper
            sx={{ border: (theme) => `1px solid ${theme.palette.divider}` }}
            elevation={0}
          >
            <Box sx={{ p: 1 }}>
              <UnitFilter />
            </Box>

            <Box sx={{ p: 1, mb: 4 }}>
              <UnitFilterBrand />
            </Box>

            <Divider />

            {rows.map((id, index) => {
              return (
                <Grid
                  container
                  sx={{
                    outline: 0,
                    // cursor: "pointer",
                    "&:hover": {
                      bgcolor: "rgb(225, 245, 254)",
                    },
                    background:
                      index % 2 === 1 ? "rgba(0, 0, 0, 0.02)" : "rgba(0, 0, 0, 0)",
                    borderBottom: "1px solid #ececec",
                    "&:last-of-type": {
                      borderBottom: 0,
                    },
                  }}
                  tabIndex={-1}
                  key={id}
                >
                  {columns.map((column, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        display="flex"
                        alignItems="center"
                        md={columnsSizes[index]}
                        key={column.code}
                      >
                        {React.createElement(column.component, {
                          ...column.componentProps,
                          unitId: id,
                        })}
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}

            <TablePagination
              rowsPerPageOptions={[5, 25, 50, 100]}
              component="div"
              count={count}
              rowsPerPage={listConfig.pageSize}
              page={listConfig.page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              labelRowsPerPage="Počet řádků"
              labelDisplayedRows={({ from, to, count }) =>
                `Zobrazeno ${from}–${to} z ${count} záznamů`
              }
              backIconButtonProps={{
                "aria-label": "předchozí",
              }}
              nextIconButtonProps={{
                "aria-label": "další",
              }}
            />
          </Paper>
        </Grid>

      </Grid>
    </Container>
  );
};

export default UnitList;
