import React from "react";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { RootState, useAppSelector } from "../../../../app/store";
import { selectAssetRelationLast } from "../../selectors/asset.selector";
import { shallowEqual } from "react-redux";
import ChipSupplier from "../../../common/components/Chip/ChipSupplier";

const Field = ({
  assetId,
  isDetail = false,
}: {
  assetId: string;
  isDetail?: boolean;
}) => {
  const rel = useAppSelector(
    (state: RootState) =>
      selectAssetRelationLast(state, {
        assetId: assetId,
        relationName: "supplierRelations",
      }),
    {
      equalityFn: shallowEqual,
    }
  );

  if (!rel) {
    return (
      <Box sx={{ p: 2, width: "100%", display: "flex" }}>
        <Typography
          variant={"body2"}
          sx={{
            fontStyle: "italic",
            color: grey[500]
          }}
        >
          není uvedeno
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2, width: "100%", display: "flex" }}>
      <ChipSupplier label={rel.supplier.title} />
    </Box>
  );
};

export const fieldSupplier = {
  code: "fieldSupplier",
  label: "Dodavatel",
  valueGetterFn: (obj: any) => {
    return "";
  },
  component: Field,
};
