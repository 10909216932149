/*
Řadí požadavky dle uživatele, který si je zobrazuje
- HQ -> dle data přiřazení na HQ, pak dle created
- ServiceCompany -> dle data přiřazení na SC, pak dle created
- ServiceTechnician -> dle data přiřazení na ST, pak dle created
- ostatní: dle created
 */
import { IssueType, ProfileType } from "../../../types";
import {
  USER_TYPE_CODE__headquarters,
  USER_TYPE_CODE__serviceCompany,
  USER_TYPE_CODE__serviceTechnician,
} from "../../../libraries/enums/userTypes";

export const getSortFn = (profile: ProfileType) => {
  switch (profile.type) {
    case USER_TYPE_CODE__headquarters: {
      return (a: IssueType, b: IssueType) => {
        const aX = a.forwardedToHeadquartersAt || a.createdAt;
        const bX = b.forwardedToHeadquartersAt || b.createdAt;
        return -aX.localeCompare(bX);
      };
    }
    case USER_TYPE_CODE__serviceCompany: {
      return (a: IssueType, b: IssueType) => {
        const aX = a.sentToServiceCompanyAt || a.createdAt;
        const bX = b.sentToServiceCompanyAt || b.createdAt;
        return -aX.localeCompare(bX);
      };
    }
    case USER_TYPE_CODE__serviceTechnician: {
      return (a: IssueType, b: IssueType) => {
        const aX = a.sentToServiceTechnicianAt || a.createdAt;
        const bX = b.sentToServiceTechnicianAt || b.createdAt;
        return -aX.localeCompare(bX);
      };
    }
    default: {
      return (a: IssueType, b: IssueType) => {
        const aX = a.createdAt;
        const bX = b.createdAt;
        return -aX.localeCompare(bX);
      };
    }
  }
};
