import React from "react";
import {
  CircularProgress,
  Divider,
  Grid,
  Hidden,
  TablePagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import {
  selectListConfig,
  selectListFetching,
  selectRevisionIdsFilteredAndSorted,
  selectRevisionsFilteredAndSortedCount,
} from "../../selectors/revisionSelectors";
import revisionSlice from "../../slices/revisionSlice";
import { fieldUnit } from "../fields/fieldUnit";
import { fieldDescription } from "../fields/fieldDescription";
import { fieldRevisionCompany } from "../fields/fieldRevisionCompany";
import { fieldNextRevisionAt } from "../fields/fieldNextRevisionAt";
import { fieldFile } from "../fields/fieldFile";
import RevisionFilter from "../RevisionFilter/RevisionFilter";
import RevisionFilterBrand from "../RevisionFilter/RevisionFilterBrand";
import { fieldAction } from "../fields/fieldAction";
import RevisionExportButton from "../RevisionExportButton/RevisionExportButton";

const RevisionList = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const fetching = useAppSelector(selectListFetching);
  const rows = useAppSelector(selectRevisionIdsFilteredAndSorted);
  const listConfig = useAppSelector(selectListConfig);
  const count = useAppSelector(selectRevisionsFilteredAndSortedCount);

  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  const columnsSizes = lgUp ? [3, 2, 2, 2, 1.5, 1.5] : [3, 3, 3, 3];

  const columns = lgUp
    ? [
        fieldDescription,
        fieldUnit,
        fieldRevisionCompany,
        fieldFile,
        fieldNextRevisionAt,
        fieldAction,
      ]
    : [
        fieldDescription,
        fieldUnit,
        fieldRevisionCompany,
        fieldFile,
        fieldNextRevisionAt,
        fieldAction,
      ];

  const handlePageChange = (event: any, page: any) => {
    dispatch(revisionSlice.actions.listConfigSet({ page: page }));
  };

  const handleRowsPerPageChange = (event: any) => {
    dispatch(
      revisionSlice.actions.listConfigSet({ pageSize: event.target.value })
    );
  };

  return (
    <>
      <Box sx={{ p: 1 }}>
        <Grid container>
          <Grid item xs={12} md={7}>
            <RevisionFilter />
          </Grid>
          <Grid item xs={12} md={5} textAlign="right">
            <Hidden mdDown>
              <RevisionExportButton />
            </Hidden>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ p: 1, mb: 4 }}>
        <RevisionFilterBrand />
      </Box>

      <Hidden mdDown>
        <Box
          sx={{
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Grid container>
            {columns.map((column, index) => {
              return (
                <Grid key={column.code} item xs={columnsSizes[index]} p={2}>
                  <Typography variant="subtitle2">{column.label}</Typography>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Hidden>

      <Hidden mdUp>
        <Divider />
      </Hidden>

      <Box hidden={!fetching} sx={{ py: 5, textAlign: "center" }}>
        <CircularProgress />
      </Box>
      <Box hidden={fetching} sx={{}}>
        {rows.map((id, index) => {
          return (
            <Grid
              container
              // onClick={() => {
              //   dispatch(revisionSlice.actions.selectedRevisionSetById(id));
              // }}
              sx={{
                outline: 0,
                // cursor: "pointer",
                "&:hover": {
                  bgcolor: "rgb(225, 245, 254)",
                },
                background:
                  index % 2 === 1 ? "rgba(0, 0, 0, 0.02)" : "rgba(0, 0, 0, 0)",
                borderBottom: "1px solid #ececec",
                "&:last-of-type": {
                  borderBottom: 0,
                },
              }}
              tabIndex={-1}
              key={id}
            >
              {columns.map((column, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    alignItems="center"
                    md={columnsSizes[index]}
                    key={column.code}
                  >
                    {React.createElement(column.component, {
                      revisionId: id,
                    })}
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </Box>

      <Divider />

      <TablePagination
        rowsPerPageOptions={[5, 25, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={listConfig.pageSize}
        page={listConfig.page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        labelRowsPerPage="Počet řádků"
        labelDisplayedRows={({ from, to, count }) =>
          `Zobrazeno ${from}–${to} z ${count} záznamů`
        }
        backIconButtonProps={{
          "aria-label": "předchozí",
        }}
        nextIconButtonProps={{
          "aria-label": "další",
        }}
      />
    </>
  );
};

export default RevisionList;
