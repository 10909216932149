//@ts-nocheck

import AuthGuard from "../features/auth/components/AuthGuard/AuthGuard";
import Layout from "../features/common/components/Layout/Layout";
import { Helmet } from "react-helmet";
import Toaster from "../features/toast/components/Toaster/Toaster";
import { useLocation } from "react-router-dom";
import React from "react";
import { indexPathnames } from "../app/router";
import UnitDialog from "../features/unit/components/UnitDialog/UnitDialog";
import IssueDetail from "../features/issue/components/IssueDetail/IssueDetail";
import RevisionDetail from "../features/revision/components/RevisionDetail/RevisionDetail";
import DocumentDetail from "../features/document/components/DocumentDetail/DocumentDetail";
import AssetDetail from "../features/asset/components/AssetDetail/AssetDetail";
import { AssetMovementDialog } from "../features/asset/components/AssetMovement/AssetMovement";

const IndexView = () => {
  const location = useLocation();

  return (
    <>
      <Helmet>
        <html lang="cs" />
        <title>{indexPathnames[location.pathname].title}</title>
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>
      <AuthGuard>
        <Layout>
          {React.createElement(indexPathnames[location.pathname].component)}
          <UnitDialog />
          <IssueDetail />
          <RevisionDetail />
          <DocumentDetail />
          <AssetDetail />
          <AssetMovementDialog />
          <Toaster />
        </Layout>
      </AuthGuard>
    </>
  );
};

export default IndexView;
