import { useState } from "react";
import { Badge, Dialog, DialogTitle, DialogContent, IconButton, List, ListItem, ListItemText, Divider, Tooltip, Chip, Box } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import releaseNotes from "./releaseNotesList";
import CloseButton from '../CloseButton/CloseButton';

const sortedReleaseNotes = [...releaseNotes].sort((a, b) => b.id - a.id); // Sort inside the component

const ReleaseNotes = () => {
  const [open, setOpen] = useState(false);
  const [lastViewed, setLastViewed] = useState(getLastViewedRelease());
  const hasNewReleases = sortedReleaseNotes.some(note => note.id > lastViewed);

  const handleOpen = () => {
    setOpen(true);
    setLastViewed(sortedReleaseNotes[0].id); // The first item now has the highest ID
    setLastViewedRelease(sortedReleaseNotes[0].id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton color="primary" onClick={handleOpen}>
        <Badge color="error" variant="dot" invisible={!hasNewReleases}>
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth={true}>
        <DialogTitle>
          Přehled novinek
          <CloseButton onClick={handleClose} />
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ pt: 0 }}>
          <List>
            {sortedReleaseNotes.map(note => (
              <ListItem key={note.id} divider
                sx={{
                  "&:last-of-type": {
                    border: 0
                  }
                }}
              >
                <ListItemText
                  primary={
                    <Tooltip title={note.date} arrow placement="right">
                      <span>
                        <strong>{note.title}</strong>
                        <Chip
                          label={note.type}
                          variant="filled"
                          size="small"
                          color={
                            note.type === "Novinky" ? "secondary" :
                              note.type === "Opravy" ? "error" :
                                note.type === "Vylepšení" ? "primary" :
                                  "default"
                          }
                          sx={{ ml: 1, position: "relative", mt: -0.5 }}
                        />
                      </span>
                    </Tooltip>}
                  secondary={<Box sx={{ mt: .5 }} dangerouslySetInnerHTML={{ __html: note.description }} />}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
};

const getLastViewedRelease = () => parseInt(localStorage.getItem("lastViewedRelease") || "0", 10);
const setLastViewedRelease = (id) => localStorage.setItem("lastViewedRelease", id);

export default ReleaseNotes;
