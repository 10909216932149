import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  RevisionSubjectType,
  RevisionSubjectUnitRequirementType,
  UnitType,
} from "../../../types";

type StateType = {
  list: EntityState<RevisionSubjectUnitRequirementType>;
  listFetching: boolean;
};

export const revisionSubjectUnitRequirementEntityAdapter =
  createEntityAdapter<RevisionSubjectUnitRequirementType>();

const initialState: StateType = {
  list: revisionSubjectUnitRequirementEntityAdapter.getInitialState(),
  listFetching: false,
};

const revisionSubjectUnitRequirementSlice = createSlice({
  name: "revisionSubjectUnitRequirement",
  initialState: initialState,
  reducers: {
    upsert: (
      state: StateType,
      action: PayloadAction<{
        unitId: UnitType["id"];
        revisionSubjectId: RevisionSubjectType["id"];
        required: boolean;
      }>
    ) => {},
    listFetch: () => {},
    listFetching: (state: StateType, action: PayloadAction<boolean>) => {
      state.listFetching = action.payload;
    },
    listSet: (
      state: StateType,
      action: PayloadAction<RevisionSubjectUnitRequirementType[]>
    ) => {
      revisionSubjectUnitRequirementEntityAdapter.setAll(
        state.list,
        action.payload
      );
    },
    listUpsertMany: (
      state: StateType,
      action: PayloadAction<RevisionSubjectUnitRequirementType[]>
    ) => {
      revisionSubjectUnitRequirementEntityAdapter.upsertMany(
        state.list,
        action.payload
      );
    },
  },
});

export default revisionSubjectUnitRequirementSlice;
